import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import {
  CarouselComponent,
  CarouselModule,
  OwlOptions,
  SlidesOutputData,
} from 'ngx-owl-carousel-o';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { BehaviorSubject, finalize } from 'rxjs';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import {
  CONFIGURATION_STEPS,
  EXPLORADOR_DIGITAL_STEPS,
  INNOVADOR_LEGAL_STEPS,
  VISIONARIO_JURIDICO_STEPS,
} from 'src/app/core/constants/user-journey.constants';
import { DialogService } from 'src/app/core/services/dialog.service';
import { UisrAuthService } from 'src/app/core/services/uisr-auth.service';
import { loadingState } from 'src/app/shared/operators/loading-state.operator';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import { APIResponse } from '../../../../core/interfaces/api.interface';
@UntilDestroy()
@Component({
  selector: 'app-user-journey',
  standalone: true,
  imports: [CommonModule, CarouselModule, NgxTippyModule, TranslateModule],
  templateUrl: './user-journey.modal.html',
  host: {
    class: 'contents',
  },
})
export class UserJourneyModal implements OnInit {
  resources = RESOURCES;
  levelOneSteps = EXPLORADOR_DIGITAL_STEPS;
  levelTwoSteps = CONFIGURATION_STEPS;
  levelThreeSteps = INNOVADOR_LEGAL_STEPS;
  levelFourSteps = VISIONARIO_JURIDICO_STEPS;
  loading = new BehaviorSubject(true);
  updating = new BehaviorSubject(true);
  loadingIds: Set<string> = new Set([]);
  completedArray = ['COMPLETED', 'OMITTED'];
  activeIndex: number = 0;
  tourCarouselOptions: OwlOptions = {
    loop: false,
    rewind: false,
    mouseDrag: false,
    autoplay: false,
    center: false,
    margin: 5,
    dots: true,
    autoHeight: true,
    autoWidth: true,
    responsive:{
      0: {
        items: 1,
      },
    }
  };
  slides: any[] = [
    {
      icon: 'fa-bicycle',
      title: 'Explorador Digital',
      steps: [],
    },
    {
      icon: 'fa-gear',
      title: 'Configuración',
      steps: [],
    },
    {
      icon: 'fa-lightbulb-on',
      title: 'Innovador Legal',
      steps: [],
    },
    {
      icon: 'fa-rocket-launch',
      title: 'Visionario Jurídico',
      steps: [],
    },
  ];

  private stepsByLevel = [
    this.levelOneSteps,
    this.levelTwoSteps,
    this.levelThreeSteps,
    this.levelFourSteps,
  ];

  private readonly _authService = inject(UisrAuthService);
  private readonly router = inject(Router);
  private readonly _apiService = inject(UisrApiServiceV2);
  public readonly dialogRef = inject(DialogRef)

  @ViewChild('stepsCarousel') stepsCarousel?: CarouselComponent;

  ngOnInit(): void {
    this._authService.permissions.pipe(untilDestroyed(this)).subscribe(() => {
      this.levelOneSteps = this.levelOneSteps.filter(
        (step) => !step.access || this._authService.hasAccess(step.access)
      );
      this.levelTwoSteps = this.levelTwoSteps.filter(
        (step) => !step.access || this._authService.hasAccess(step.access)
      );
      this.levelThreeSteps = this.levelThreeSteps.filter(
        (step) => !step.access || this._authService.hasAccess(step.access)
      );
      this.levelFourSteps = this.levelFourSteps.filter(
        (step) => !step.access || this._authService.hasAccess(step.access)
      );

      this.slides[0].steps = this.levelOneSteps;
      this.slides[1].steps = this.levelTwoSteps;
      this.slides[2].steps = this.levelThreeSteps;
      this.slides[3].steps = this.levelFourSteps;

      this.getUserJourney();
    });
  }

  getUserJourney() {
    const allSteps = [
      ...this.levelOneSteps,
      ...this.levelTwoSteps,
      ...this.levelThreeSteps,
      ...this.levelFourSteps,
    ];

    this._apiService
      .get(RESOURCES.journeyByUser)
      .pipe(
        finalize(() => this.loading.next(false)),
        untilDestroyed(this)
      )
      .subscribe({
        next(res: APIResponse<any>) {
          const steps = res.data.steps;

          steps.forEach((step: any) => {
            const foundStep = allSteps.find((s) => s.step == step.step);
            if (foundStep) {
              foundStep.status = step.status;
            }
          });
        },
      });
  }

  getCompletedPercentage() {
    if (this.stepsCarousel) {
      const steps = this.stepsByLevel[this.activeIndex];
      const completed = steps.filter((e) =>
        this.completedArray.includes(e.status)
      );

      return (completed.length / steps.length) * 100;
    } else {
      return 0;
    }
  }

  getLevelLength() {
    if (this.stepsCarousel) {
      return this.stepsByLevel[this.activeIndex].length;
    } else {
      return 0;
    }
  }

  getCompletedSteps() {
    if (this.stepsCarousel) {
      return this.stepsByLevel[this.activeIndex].filter((e) =>
        this.completedArray.includes(e.status)
      ).length;
    } else {
      return 0;
    }
  }

  onStepClick(step: any) {
    this.router.navigate([step.route], { queryParams: step.queryParams });
  }

  setActiveSlide(event: SlidesOutputData) {
    this.activeIndex = event.startPosition || 0;
  }

  markAsComplete(item: any) {
    this.loadingIds.add(item.step);

    // Cambiar inmediatamente el estado para actualizar la UI
    item.status = item.status === 'OMITTED' ? 'INCOMPLETE' : 'OMITTED';

    // Datos a enviar al servidor
    const data = {
      step: item.step,
      status: item.status,
    };

    this._apiService
      .patch(this.resources.journeyByUserStep, data)
      .pipe(
        loadingState(this.updating),
        finalize(() => this.loadingIds.delete(item.step)),
        untilDestroyed(this)
      )
      .subscribe({
        next: (res: APIResponse<string>) => {
          if (res.success) {
          }
        },
      });
  }

  getStyle(status: any) {
    if (status == 'OMITTED' || status == 'COMPLETED') {
      return 'fa-solid fa-circle-check';
    } else return 'fa-regular fa-circle';
  }
}
