<div class="bg-pearl-100 border-b border-slate-200 z-30">
    <div class="px-4 sm:px-6 lg:px-8 relative flex items-center justify-between py-2.5 -mb-px">
        <!-- Header: Izquierda -->
        <div class=" gap-2 inline-flex items-center">
            <!-- Abrir o cerrar el menú en mobile -->
            <button (click)="openSidebarRequest.emit(true)" class="text-slate-500 hover:text-slate-600 lg:hidden"
                aria-controls="menu">
                <span class="sr-only">Abrir Menú</span>
                <i class="fa-solid fa-bars text-current" style="font-size: 1.25rem"></i>
            </button>

            <!-- Botón para iniciar el tour de la página actual -->
            <button *ngIf="steps && steps[0]" (click)="showTutorial()" class="text-slate-500 hover:text-slate-600"
                [ngxTippy]="'Ver Ayuda'" [tippyProps]="{hideOnClick: true}" id="help-button">
                <span class="sr-only">Ver Ayuda</span>
                <i class="fa-regular fa-circle-question text-current pointer-events-none"
                    style="font-size: 1.25rem"></i>
            </button>
        </div>
        <!-- Header: Derecha -->
        <div class="flex items-center">

            <!-- Notifications button -->
            <div class="relative inline-flex">
                <button type="button" [cdkMenuTriggerFor]="notificationsOptions" (cdkMenuClosed)="onMenuClosed()"
                    class="relative grid size-8 shrink-0 place-content-center rounded hover:bg-slate-300/60 hover:shadow-sm"
                    aria-haspopup="true" aria-expanded="open" (click)="newNotifications = false">
                    <i class="text-[1rem]"
                        [ngClass]="newNotifications ? 'fa-solid text-rose-500 fa-bell-on' : 'fa-duotone fa-bell'"></i>
                </button>
                <ng-template #notificationsOptions>
                    <ul class="mt-1 bg-pearl-100 border border-slate-200 rounded shadow-lg min-w-44 py-1.5 animate__animated animate__fadeIn animate__faster max-w-96 max-h-[90svh] lg:max-h-96 overflow-y-auto overflow-x-hidden flex flex-col"
                        *ngIf="notifications" cdkMenu>
                        <div class="pt-0.5 pb-2 px-3 border-b border-slate-200 grid grid-cols-2 gap-6">
                            <h2 class="font-medium text-slate-800" translate="NOTIFICATIONS_STRING"></h2>
                            <label class="flex flex-row items-center justify-end gap-2">
                                <span class="text-xs italic text-left select-none text-slate-400 sm:text-center">
                                    {{ (onlyShow ? 'ONLY_READ_STRING' : 'READ_STRING') | translate }}
                                </span>
                                <div class="form-switch">
                                    <input type="checkbox" class="sr-only" [(ngModel)]="onlyShow">
                                </div>
                            </label>
                        </div>
                        <div class="flex flex-col divide-y-2 divide-slate-100 bg-white">
                            <ng-container
                                *ngIf="(notifications | filterByBool:'bolNotify':!onlyShow) as filteredNotifications; else noNotifications">
                                <ng-container *ngIf="filteredNotifications.length; else noNotifications">
                                    <div class="flex justify-end mt-2 px-3" *ngIf="onlyShow">
                                        <button class="text-xs text-slate-400 hover:text-slate-600"
                                            (click)="markAllAsRead($event);">Solo no leídas</button>
                                        <div *ngIf="isLoadingMarkAsRead">
                                            <svg class="animate-spin h-6 w-6  text-indigo-500"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <path class="text-indigo-500" xmlns="http://www.w3.org/2000/svg"
                                                    opacity="0.2" fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                                    fill="currentColor" />
                                                <path class="text-indigo-500" xmlns="http://www.w3.org/2000/svg"
                                                    d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"
                                                    fill="currentColor" />
                                            </svg>
                                        </div>
                                    </div>
                                    <li *ngFor="let notification of filteredNotifications" cdkMenuItem>
                                        <div class="px-3 py-5 bg-white cursor-pointer flex items-start flex-shrink-0 min-h-[4.5rem] hover:bg-gray-100/50 gap-3 mt-1"
                                            [title]="sanitizeText2(notification.notification.text)">
                                            <img aria-label="icon" role="img" (click)="markAsRead(notification)"
                                                class="size-8 shrink-0 shadow-inner border border-slate-200 rounded-full"
                                                [src]="getImageUrl(notification)">
                                            <div (click)="markAsRead(notification)">
                                                <p class="focus:outline-none text-sm leading-[1.15]"
                                                    [innerHTML]="sanitizeText(notification.notification.text)">
                                                </p>
                                                <p class="focus:outline-none text-xs leading-3 pt-1 text-gray-500">
                                                    hace
                                                    {{calculateTimePassed(notification.notification.createdAt)}}</p>
                                            </div>
                                            <div *ngIf="!isNotificationUpdate(notification)"
                                                (click)="onlyMarkAsRead(notification); $event.stopPropagation()"
                                                class="ml-auto pl-3">
                                                <i class="fa-regular fa-eye text-slate-600 pointer-events-none"
                                                    *ngIf="!notification.bolNotify" style="font-size: 1rem"></i>
                                            </div>
                                            <div *ngIf="isNotificationUpdate(notification)">
                                                <svg class="animate-spin h-6 w-6  text-indigo-500"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <path class="text-indigo-500" xmlns="http://www.w3.org/2000/svg"
                                                        opacity="0.2" fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                                        fill="currentColor" />
                                                    <path class="text-indigo-500" xmlns="http://www.w3.org/2000/svg"
                                                        d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"
                                                        fill="currentColor" />
                                                </svg>
                                            </div>
                                        </div>
                                    </li>
                                    <li [ngClass]="{'h-0 overflow-hidden':isPaginationComplete}"
                                        (click)="getNotify(); $event.stopPropagation()">
                                        <!-- boton de traer mas notificaciones -->
                                        <div class="flex justify-center py-2 ">
                                            <button class="text-xs text-slate-400 hover:text-slate-600">Ver
                                                más</button>
                                            <svg *ngIf="isLoading"
                                                class="animate-spin ml-1 mr-3 h-5 w-5 text-indigo-500"
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <path class="text-indigo-500" xmlns="http://www.w3.org/2000/svg"
                                                    opacity="0.2" fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                                    fill="currentColor" />
                                                <path class="text-indigo-500" xmlns="http://www.w3.org/2000/svg"
                                                    d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z"
                                                    fill="currentColor" />
                                            </svg>
                                        </div>
                                    </li>
                                </ng-container>
                            </ng-container>

                            <ng-template #noNotifications>
                                <li cdkMenuItem>
                                    <a role="button" class="app-menu-option" translate="NO_NOTIFICATIONS_STRING">
                                    </a>
                                </li>
                            </ng-template>
                        </div>
                    </ul>
                </ng-template>
            </div>

            <!-- Info button -->
            <div class="relative inline-flex">
                <button
                    class="relative grid size-8 shrink-0 place-content-center rounded hover:bg-slate-300/60 hover:shadow-sm text-slate-500"
                    [menuTriggerFor]="infoOptions">
                    <span class="pointer-events-none sr-only" translate="INFORMATION_STRING"></span>
                    <i class="fa-regular fa-circle-info"></i>
                </button>
                <ng-template #infoOptions>
                    <div
                        class="uisr-dropdown-menu animate__animated animate__fadeIn animate__faster shadow-xl max-w-4xl w-[90svw] ml-6">
                        <div cdkMenu
                            class="grid sm:grid-cols-2 px-3 py-4 mx-auto text-gray-900 dark:text-white md:px-4">
                            <div class="font-semibold">Autogestión</div>
                            <div class="font-semibold max-sm:order-5">Contactanos</div>
                            <div cdkMenuItem class="py-1 px-2">
                                <a (click)="helpCenterAnalytics()"
                                    href="https://midespacho.notion.site/Centro-de-Ayuda-2b498667b40943079f573c9b9e1ab539"
                                    class="block hover:bg-slate-50 dark:hover:bg-gray-700" target="_blank"
                                    rel="noopener noreferrer">
                                    <div
                                        class="text-sm font-medium text-primary-100 dark:text-primary-100 hover:text-primary-300 dark:hover:text-primary-200 flex items-center justify-start disabled:bg-slate-100 disabled:text-slate-700 disabled:text-opacity-75 w-full">
                                        🆘 Centro de Ayuda
                                    </div>
                                    <div class="text-xs flex justify-start text-left text-gray-500 dark:text-gray-400">
                                        Te ofrecemos recursos para optimizar tu uso de la plataforma. </div>
                                </a>
                            </div>
                            <div cdkMenuItem class="py-1 px-2 max-sm:order-6">
                                <button id="intercom-launcher" (click)="intercomService.showIntercom()"
                                    class="block hover:bg-slate-50 dark:hover:bg-gray-700">
                                    <div
                                        class="text-sm font-medium text-primary-100 dark:text-primary-100 hover:text-primary-300 dark:hover:text-primary-200 flex items-center justify-start disabled:bg-slate-100 disabled:text-slate-700 disabled:text-opacity-75 w-full">
                                        💬 Chat en vivo</div>
                                    <div class="text-xs text-left text-gray-500 dark:text-gray-400">
                                        Encuentra un asesor para resolver tus dudas.
                                    </div>
                                </button>
                            </div>
                            <div cdkMenuItem *ngIf="assistantChatService.assistantAvailable()" class="py-1 px-2">
                                <button (click)="toggle()" class="block hover:bg-slate-50 dark:hover:bg-gray-700">
                                    <div
                                        class=" text-sm font-medium text-primary-100 dark:text-primary-100 hover:text-primary-300 dark:hover:text-primary-200 flex items-center justify-start disabled:bg-slate-100 disabled:text-slate-700 disabled:text-opacity-75 w-full">
                                        <div class="mr-2 -ml-1">
                                            <img src="/assets/images/isotipo.png" alt="Amparo IA" class="w-6 h-6">
                                        </div>
                                        Asistencia con Amparo IA
                                    </div>
                                    <div class="text-xs flex justify-start text-left text-gray-500 dark:text-gray-400">
                                        Tu asistente legal para gestionar y simplificar tu trabajo.</div>
                                </button>
                            </div>
                            <div cdkMenuItem class="py-1 px-2 max-sm:order-7">
                                <a href="https://calendly.com/valearm/bienvenido-agenda-una-implementacion-de-midesp-clon-1?month=2024-09"
                                    target="_blank" rel="noopener noreferrer" (click)="this.scheduleMeetingAnalytics()"
                                    class="block hover:bg-slate-50 dark:hover:bg-gray-700">
                                    <div
                                        class=" text-sm font-medium text-primary-100 dark:text-primary-100 hover:text-primary-300 dark:hover:text-primary-200 flex items-center justify-start disabled:bg-slate-100 disabled:text-slate-700 disabled:text-opacity-75 w-full">
                                        🗓️ Agenda una reunión con nosotros</div>
                                    <div class="text-xs flex justify-start text-left text-gray-500 dark:text-gray-400">
                                        Agendemos una reunión, queremos ayudarte a resolver tus dudas.</div>
                                </a>
                            </div>
                            <div cdkMenuItem class="py-1 px-2">
                                <button (click)="openUserJourney()"
                                    class="block hover:bg-slate-50 dark:hover:bg-gray-700">
                                    <div
                                        class="text-sm font-medium text-primary-100 dark:text-primary-100 hover:text-primary-300 dark:hover:text-primary-200 flex items-center justify-start disabled:bg-slate-100 disabled:text-slate-700 disabled:text-opacity-75 w-full">
                                        ⛰️ Tour MiDespacho</div>
                                    <div class="text-xs flex justify-start text-left text-gray-500 dark:text-gray-400">
                                        Aprende más sobre las funciones que tenemos para ti.</div>
                                </button>
                            </div>
                            <div cdkMenuItem class="py-1 px-2 max-sm:order-8">
                                <div class="block hover:bg-slate-50 dark:hover:bg-gray-700">
                                    <div
                                        class=" text-sm font-medium text-primary-100 dark:text-primary-100 hover:text-primary-300 dark:hover:text-primary-200 flex items-center justify-start disabled:bg-slate-100 disabled:text-slate-700 disabled:text-opacity-75 w-full">
                                        📞 Llámanos</div>
                                    <div class="text-xs text-left text-gray-500 dark:text-gray-400">+52 (55) 62 83 03 01
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>

            <!-- Acceso al Calendario -->
            <a [routerLink]="'/calendar'"
                class="cursor-pointer relative grid size-8 shrink-0 place-content-center rounded hover:bg-slate-300/60 hover:shadow-sm text-slate-500"
                (click)="trackCalendarClick()">
                <span class="pointer-events-none sr-only" translate="CALENDAR"></span>
                <i class="fa-regular fa-calendar"></i>
            </a>

            <!-- Assistant button -->
            <div *ngIf="assistantChatService.assistantAvailable() && (userData?.idWorkspace || userData?.id_workspace_member)"
                class="relative inline-flex ml-2" id="assistant-button">
                <button (click)="toggle()"
                    class="transition-colors inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border shadow-sm duration-150 ease-in-out bg-white border-slate-200 hover:border-slate-300 text-slate-500"
                    ngxTippy [tippyProps]="{ placement: 'auto', hideOnClick: true }"
                    [ngxTippy]="'Asistente con Inteligencia Artificial'">
                    <div class="-ml-1">
                        <img src="/assets/images/amparo-isotipo.webp" alt="Amparo IA" class="size-6">
                    </div>
                    <img src="/assets/images/amparo.webp" alt="Amparo IA" class="h-[0.85rem] -ml-3">
                </button>
            </div>

            <!-- User button -->
            <div class="relative inline-flex ml-3">
                <button type="button" [menuTriggerFor]="userOptions"
                    class="uisr-btn-8 bg-slate-100 hover:bg-slate-200 shadow-inner" aria-haspopup="true"
                    aria-expanded="open">
                    <img class="pointer-events-none w-8 h-8 rounded-full" [src]="parsePhoto()" width="32" height="32"
                        alt="User" />
                </button>
                <ng-template #userOptions>
                    <ul class="uisr-dropdown-menu animate__animated animate__fadeIn animate__faster" *ngIf="userData"
                        cdkMenu>
                        <div class="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200">
                            <div class="font-medium text-slate-800">{{(userData.first_name + " "
                                +
                                userData.last_name) | titlecase}}</div>
                            <div class="text-xs text-slate-500 italic">{{userData.email || ""}}
                            </div>
                        </div>
                        <li cdkMenuItem>
                            <a role="button"
                                class="font-medium text-sm text-primary-100 hover:text-primary-200 flex items-center py-1 px-3 hover:bg-slate-50"
                                [routerLink]="'/settings'">{{'SETTINGS_STRING' | translate}}</a>
                        </li>
                        <li cdkMenuItem>
                            <a role="button" [routerLink]="'/law-firm/invitations'"
                                class="font-medium text-sm text-primary-100 hover:text-primary-200 flex items-center py-1 px-3 hover:bg-slate-50"
                                translate="INVITATION_STRING">
                            </a>
                        </li>
                        <li cdkMenuItem>
                            <a role="button" [routerLink]="'/security/privacy'"
                                class="font-medium text-sm text-primary-100 hover:text-primary-200 flex items-center py-1 px-3 hover:bg-slate-50"
                                translate="POLICIE_STRING"></a>
                        </li>
                        <li cdkMenuItem>
                            <a role="button" class="app-menu-option-danger" (click)="signOut()"
                                translate="SIGN_OUT_STRING"></a>
                        </li>
                    </ul>
                </ng-template>
            </div>
        </div>
    </div>
    <div *ngIf="lastPayment && !lastPayment.payment_status && !lastPayment.next_payment_attempt && userData?.stripe_customer_id && showPaymentErrorBanner"
        class="px-4 py-2 text-sm border bg-rose-100 border-rose-200 text-rose-600 left-0 right-0 top-16">
        <div class="flex w-full gap-2 items-start">
            <i class="fa-solid fa-circle-xmark text-[1.25rem] cursor-pointer"></i>
            <div>Su último pago no ha podido ser procesado, por favor contacte con soporte para asistencia. <a
                    class='font-bold' href="/law-firm/settings/payments">Ver Pagos</a></div>
        </div>
    </div>
    <div *ngIf="lastPayment?.next_payment_attempt && showPaymentErrorBanner"
        class="px-4 py-2 text-sm border bg-rose-100 border-rose-200 text-rose-600 left-0 right-0 top-16">
        <div class="flex w-full gap-2 items-start">
            <i class="fa-solid fa-circle-xmark text-[1.25rem]"></i>
            <div>Su último pago no ha podido ser procesado, se intentará procesar de nuevo en {{
                lastPayment?.next_payment_attempt | date:'MMM dd, yyyy' }}. <a class='font-bold'
                    href="/law-firm/settings/payments">Ver Pagos</a></div>
            <button class="opacity-70 hover:opacity-80 ml-3 mt-[3px] items-end" (click)="closePaymentErrorBanner()">
                <div class="sr-only">Close</div>
                <svg class="w-4 h-4 fill-current">
                    <path
                        d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z">
                    </path>
                </svg>
            </button>
        </div>
    </div>
</div>